import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"
import BaseContainer from "src/components/Base/BaseContainer"
import BoxList from "src/components/BoxList"

const subpages = [
  {
    title: "Jobs",
    subtitle: "See open positions within Anima International",
    path: "/get-involved/jobs",
    icon: "feather-users",
  }
]

const GetInvolvedPage = () => {
  const [observed, setObserved] = useState(false)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo title="Get involved" />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">Get involved</PrimaryTitle>
      </PageHeader>
      <BaseContainer>
        <BoxList items={subpages} />
      </BaseContainer>
    </>
  )
}

const PageHeader = styled.header`
  padding: 180px 0 50px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding: 250px 0 100px;
  }
`

export default GetInvolvedPage
